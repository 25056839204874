import { eventSender } from 'Lib/event-sender/event-sender';

const cSliderOn = 'owl-carousel';
const elParent = document?.querySelector('.gift-collections');
const elsBtn = elParent?.querySelectorAll('.gift-collections__btn');
const elHeaderWidth = (Number(elParent?.querySelector('.gift-collections__header').offsetWidth) - 64) / Number(elParent?.querySelectorAll('.gift-collections__header-item').length - 1);

function setBgNumber(el) {
    setTimeout(() => {
        el.classList.add('gift-collections__header-item_active');
    }, 800);
}

const elBtnLinkResult = elParent?.querySelector('.gift-collections__btn_result');
const objLinkResult = {};
let objTextResult = [];
let objTextStepResult = [];
let objTextStepResultTemp = [];
let stringLinkResult = '';

elParent?.querySelectorAll('.gift-collections__body-item-select-btn').forEach((itemBtn) => {
    itemBtn.addEventListener('click', (e) => {
        const target = e.target;
        const attrVisibleChild = target.getAttribute('data-visible-child');
        const attrVisibleValue = target.getAttribute('data-value');
        const elsTargetLine = elParent.querySelectorAll(`[data-visible-parent="${attrVisibleChild}"][data-when-visible="${attrVisibleValue}"]`);
        const btnParent = target.closest('.gift-collections__body-item-select');
        const getDataName = btnParent.getAttribute('data-name');

        target.classList.toggle('gift-collections__body-item-select-btn_active');

        /* Проверка есть ли у пункта дочерние пункты, если есть, выводим:
        Атрибуты "data-visible-parent", "data-visible-child" - связывают родителя с дочерним элементом
        Атрибут в категории кнопок выбора "data-when-visible" сравнивает значение с атрибутом кнопки
        "data-value" и выводит соответствующие категории */
        if (attrVisibleChild) {
            elParent.querySelectorAll(`[data-visible-parent="${attrVisibleChild}"]`).forEach((itemVisibleParent) => itemVisibleParent.setAttribute('style', 'display: none'));

            elsTargetLine.forEach((itemVisibleParent) => {
                itemVisibleParent.setAttribute('style', 'display: flex');

                itemVisibleParent.querySelectorAll('.gift-collections__body-item-select-btn').forEach((item) => {
                    item.classList.remove('gift-collections__body-item-select-btn_active');
                });

                itemVisibleParent.querySelector('.gift-collections__body-item-select-btn').classList.add('gift-collections__body-item-select-btn_active');
            });
        }

        // Установка гет параметров на страницу с результатом
        if (objLinkResult[getDataName]) {
            if (objLinkResult[getDataName].some((item) => item === attrVisibleValue)) {
                objLinkResult[getDataName] = objLinkResult[getDataName].filter((name) => name !== attrVisibleValue);
            } else {
                objLinkResult[getDataName].push(attrVisibleValue);
            }
        } else {
            objLinkResult[getDataName] = [];
            objLinkResult[getDataName].push(attrVisibleValue);
        }

        // Установка текста для выбранных элементов
        if (objTextResult.some((item) => item === target.textContent)) {
            objTextResult = objTextResult.filter((name) => name !== target.textContent);
        } else {
            objTextResult.push(target.textContent);
        }

        if (objTextStepResult.some((item) => item === target.textContent)) {
            objTextStepResult = objTextStepResult.filter((name) => name !== target.textContent);
        } else {
            objTextStepResult.push(target.textContent);
        }

        // Обновление гет параметров также для дочерних пунктов
        if (attrVisibleChild) {
            elsTargetLine.forEach((itemVisibleParent) => {
                objLinkResult[itemVisibleParent.closest('.gift-collections__body-item-select').getAttribute('data-name')] = itemVisibleParent.firstElementChild.getAttribute('data-value');
            });
        }
        //

        stringLinkResult = '';

        Object.keys(objLinkResult).forEach((key) => {
            let currentValue = '';

            if (Array.isArray(objLinkResult[key])) {
                objLinkResult[key].forEach((item) => {
                    currentValue += `&${key}[]=${item}`;
                });
            }

            stringLinkResult += currentValue;
        });

        elBtnLinkResult.setAttribute('href', `?search_book=y${stringLinkResult}`);
        //
    });
});

elsBtn?.forEach((item) => {
    item.addEventListener('click', (e) => {
        const target = e.target;
        const elParentTarget = target.closest('.gift-collections__body-item');
        const isBack = target.classList.contains('gift-collections__btn_back');
        const elTitleActive = elParent.querySelector('.gift-collections__body-item_active .gift-collections__title');

        // Смена активной секции с вопросами
        if (isBack) {
            elParentTarget.previousElementSibling.classList.add('gift-collections__body-item_active');
        } else {
            elParentTarget.nextElementSibling.classList.add('gift-collections__body-item_active');
        }

        elParentTarget.classList.remove('gift-collections__body-item_active');
        // Добавление подзаголовка, который содержит выбранные элементы
        elParent.querySelector('.gift-collections__body-item_active .gift-collections__subtitle')?.remove();

        if (isBack) {
            objTextStepResult = objTextStepResultTemp;
        } else {
            elParent.querySelector('.gift-collections__body-item_active .gift-collections__title').insertAdjacentHTML('afterend', `
                <div class="gift-collections__subtitle">${objTextResult.join(', ')}</div>
            `);

            eventSender.customDL({
                event: 'eksmo',
                eventAction: 'click',
                eventCategory: `Выбор подарка - ${elTitleActive.textContent}`,
                eventLabel: objTextStepResult.join(' / '),
            });

            objTextStepResultTemp = objTextStepResult;
            objTextStepResult = [];
        }

        // Анимация и смена состояний блока с прогрессом
        if (elParent.querySelector('.gift-collections__header-item_old-active')) {
            const elsHeaderOldActive = elParent.querySelectorAll('.gift-collections__header-item_old-active');

            if (isBack) {
                const elHeaderOldActiveLast = elsHeaderOldActive[elsHeaderOldActive.length - 1];

                elHeaderOldActiveLast.classList.remove('gift-collections__header-item_old-active');
                elHeaderOldActiveLast.querySelector('.gift-collections__header-item-number-line').style.width = `0`;
                elHeaderOldActiveLast.nextElementSibling?.classList?.remove('gift-collections__header-item_active');
            } else {
                const elHeaderOldActiveLast = elsHeaderOldActive[elsHeaderOldActive.length - 1].nextElementSibling;

                elHeaderOldActiveLast.classList.add('gift-collections__header-item_old-active');
                elHeaderOldActiveLast.querySelector('.gift-collections__header-item-number-line').style.width = `${elHeaderWidth}px`;
                setBgNumber(elHeaderOldActiveLast.nextElementSibling);
            }
        } else {
            const elHeaderItem = elParent.querySelector('.gift-collections__header-item');

            elHeaderItem.classList.add('gift-collections__header-item_old-active');
            elHeaderItem.querySelector('.gift-collections__header-item-number-line').style.width = `${elHeaderWidth}px`;
            setBgNumber(isBack ? elHeaderItem.previousElementSibling : elHeaderItem.nextElementSibling);
        }
    });
});

elBtnLinkResult?.addEventListener('click', () => {
    eventSender.customDL({
        event: 'eksmo',
        eventAction: 'click',
        eventCategory: `Выбор подарка - ${elParent.querySelector('.gift-collections__body-item_active .gift-collections__title').textContent}`,
        eventLabel: objTextStepResult.join(' / '),
    });
});

$('.gift-collections__prompt').trigger('destroy.owl.carousel')
    .removeClass(cSliderOn)
    .addClass(cSliderOn)
    .owlCarousel({
        items: 1,
        margin: 0,
        slideBy: 1,
        dots: false,
        nav: true,
        navClass: [
            'gift-collections__prompt-prev',
            'gift-collections__prompt-next',
        ],
        navText: ['', ''],
    });
