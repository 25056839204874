import 'COMMON';
import { svgSpriteLoader } from 'Lib/svg-sprite-loader/svg-sprite-loader';
import { oLike } from 'Lib/like/like';
import 'Bem/site/collections-slider/collections-slider';
import 'Bem/site/spoiler/spoiler';
import 'Bem/site/subscribe-btn/subscribe-btn'; // Удалить EKSMORU-2301
import 'Bem/site/book/book';
import 'Bem/site/book-preview/book-preview';
import 'Bem/site/audio-play/audio-play';
import 'Bem/site/tabs/tabs';
import 'Bem/site/img-loader/img-loader';
import { initPostload as booksSliderInitPostload } from 'Bem/site/books-slider/books-slider';
import 'Bem/site/popup-subscribe/popup-subscribe';
import 'Bem/site/sales-book/sales-book';
import 'Bem/site/header-page/header-page';
import 'Bem/site/gift-collections/gift-collections';
import 'Bem/site/finder/finder';
import 'Bem/site/switchbox/switchbox';
import 'Bem/site/filter-formats/filter-formats';
import 'Bem/site/collection/collection';
import 'Bem/site/popup-propavshaya/popup-propavshaya'; // Удалить, когда будет неактуален EKSMORU-4218

const $body = $('body');

svgSpriteLoader('/site/collections/svg/sprite.svg?v=20180320');
oLike.init('.js-book-like, .js-like');

$body.on('eksmoPagenavAjax', () => {
    oLike.init('.js-book-like');
    booksSliderInitPostload($('.books-slider_post-load'));
});