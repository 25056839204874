import { eventSender } from 'Lib/event-sender/event-sender';
import { oGrecaptcha } from 'Lib/grecaptcha/grecaptcha';
import { isNoEmptyString } from 'Lib/is-no-empty-string/is-no-empty-string';

const sBtn = '.subscribe-btn';
const sFormBtn = '.subscribe-btn__btn';
const sForm = '.subscribe-btn__form';
const sInput = '.subscribe-btn__input';
const cLoadM = 'subscribe-btn_load';
const cFailM = 'subscribe-btn_fail';
const cSuccessM = 'subscribe-btn_success';
const defErrorText = 'Введите корректный Email';

function postForm($form, $parent, href, o) {
    if ($form.length) {
        oGrecaptcha.render($form);

        oGrecaptcha.check($form).done((resp) => {
            o['g-recaptcha-response'] = resp;
            o.http_referer = document.referrer;

            $.post(href, o, (value) => {
                const data = $.parseJSON(value);
                const $success = $parent.find('.subscribe-btn__success-text');
                const $fail = $parent.find('.subscribe-btn__error');

                if (typeof data.STATUS !== 'undefined' && typeof data.MESSAGE !== 'undefined') {
                    if (data.STATUS) {
                        const dataLayerEvent = $form.attr('data-layer-event');

                        $fail.html('');
                        $success.html(data.MESSAGE);
                        $parent.find('.subscribe-btn__checkbox').remove();
                        $parent.removeClass(cFailM);
                        $parent.addClass(cSuccessM);

                        if (isNoEmptyString(dataLayerEvent)) {
                            eventSender.customDL({ 'event': dataLayerEvent });
                        }

                        if (isNoEmptyString(o.vendor)) {
                            eventSender.eventSubscribe(o.vendor);
                        }
                    } else {
                        $success.html('');
                        $fail.html(data.MESSAGE);
                        $parent.removeClass(cSuccessM);
                        $parent.addClass(cFailM);
                    }

                    $parent.removeClass(cLoadM);
                } else {
                    $success.html('');
                    $fail.html(defErrorText);
                    $parent.removeClass(cLoadM);
                    $parent.removeClass(cSuccessM);
                    $parent.addClass(cFailM);
                }
            });
        });
    }
}

$(document).on('click', sBtn, function (e) {
    const $this = $(this);
    const cMod = 'subscribe-btn_cont';
    let additionalClasses = '';
    const w = $this.outerWidth();
    const h = $this.outerHeight();
    const additionalAttr = ` style="width:${w}px; height:${h}px;"`;
    const arClasses = $this.attr('class').split(' ');
    const arData = $this.data();
    let attrDataLayerEvent = '';
    let attrDataFn = '';
    const dataLayerEvent = $this.attr('data-layer-event');
    const dataFn = $this.attr('data-fn');
    let placeholder = '';
    const dataPlaceholder = $this.attr('data-placeholder');
    const dataCheckboxAccept = $this.attr('data-checkbox-accept');
    const dataNoIcon = $this.attr('data-no-icon');
    const dataAction = $this.attr('data-action');
    let htmlHiddenInputs = '';

    Object.keys(arData).forEach((i) => {
        htmlHiddenInputs += `<input type="hidden" name="${i}" value="${arData[i]}">`;
    });

    arClasses.forEach((item) => {
        if (item.indexOf('btn_') !== 0 && item !== 'btn') {
            additionalClasses += ` ${item}`;
        }
    });

    if ($this.hasClass('btn_center')) {
        additionalClasses += ' subscribe-btn_center';
    }

    if (typeof dataLayerEvent === 'string') {
        attrDataLayerEvent = ` data-layer-event="${dataLayerEvent}"`;
    }

    if (typeof dataFn === 'string') {
        attrDataFn = ` data-fn="${dataFn}"`;
    }

    if (typeof dataPlaceholder === 'string') {
        placeholder = dataPlaceholder;
    }

    const htmlContent = `
        <div class="subscribe-btn ${cMod}${additionalClasses}"${additionalAttr}>
            <form action="${dataAction || '/ajax/subscribe/sender/'}" class="subscribe-btn__form" method="post"${attrDataLayerEvent}${attrDataFn}>
                ${!dataNoIcon === true ? '<svg class="subscribe-btn__icon"><use xlink:href="#envelope-color"></use></svg>' : ''}
                <input
                    type="email"
                    name="email"
                    class="subscribe-btn__input"
                    placeholder="${placeholder || 'Введите Email'}"
                    autocapitalize="off"
                    autocorrect="off"
                    autocomplete="email"
                >
                <button type="submit" class="subscribe-btn__btn" value="">
                    <svg class="subscribe-btn__btn-icon"><use xlink:href="#send"></use></svg>
                </button>

                <svg class="subscribe-btn__load-icon"><use xlink:href="#loading"></use></svg>
                <svg class="subscribe-btn__fail-icon"><use xlink:href="#warning"></use></svg>
                ${htmlHiddenInputs}
                <div class="subscribe-btn__success">
                    <div class="subscribe-btn__success-cont">
                        <span class="subscribe-btn__success-icon"></span>
                        <span class="subscribe-btn__success-text"></span>
                    </div>
                </div>
            </form>
            <div class="subscribe-btn__error"></div>

            ${dataCheckboxAccept ? `
                <label class="subscribe-btn__checkbox checkbox checkbox_no-hover">
                    <input type="checkbox" name="consent" checked="checked">
                    <span class="checkbox__button"></span>
                    <span class="checkbox__content-offset form-custom__checkbox-text">${dataCheckboxAccept}</span>
                </label>` : ''}
        </div>
    `;

    e.preventDefault();

    if (!$this.hasClass(cMod)) {
        $this.replaceWith(htmlContent);
        $(sInput).focus();
    }
});

$(document).on('click', sFormBtn, function () {
    const $thisBtn = $(this);
    const $form = $thisBtn.closest(sForm);
    const $parent = $thisBtn.closest(sBtn);
    const $success = $parent.find('.subscribe-btn__success-text');
    const $fail = $parent.find('.subscribe-btn__error');

    if ($form.length && $parent.length) {
        const href = $form.attr('action');
        const o = {};

        if (isNoEmptyString(href)) {
            if (!$parent.hasClass(cLoadM) && !$parent.hasClass(cSuccessM)) {
                $parent.addClass(cLoadM);

                $form.find('input').each(function () {
                    o[$(this).attr('name')] = $(this).val();
                });

                if (o.email.length > 2) {
                    const dataFn = $form.attr('data-fn');

                    if (typeof dataFn === 'string') {
                        $.get(`/ajax/form/?fn=${dataFn}`, (data) => {
                            if (data.length) {
                                o.hash = data;
                                postForm($form, $parent, href, o);
                            }
                        });
                    } else {
                        postForm($form, $parent, href, o);
                    }
                } else {
                    $success.html('');
                    $fail.html(defErrorText);
                    $parent.removeClass(cLoadM);
                    $parent.removeClass(cSuccessM);
                    $parent.addClass(cFailM);
                }
            }
        }
    }

    return false;
});

$(document).on('keypress paste', sInput, function () {
    const $parent = $(this).closest(sBtn);

    if ($parent.length) {
        $parent.removeClass(cFailM);
    }
});