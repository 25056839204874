import { onScreen } from 'Lib/on-screen/on-screen';
import { loadSrcOrBg } from 'Lib/load-src-or-bg/load-src-or-bg';
import { Popup } from 'Lib/popup/popup';

const popup = new Popup('popup', document.querySelector('.header-page__call-order'));

onScreen($('.header-page'), loadSrcOrBg);

document.querySelector('.header-page__btn_order-call')?.addEventListener('click', () => {
    popup.open();
});